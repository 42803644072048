import axios from "axios";
import { appActions } from "store/slices/App.slice";
import { store } from "store/store";

export const baseUrl = {
  // dev: "https://dev-admin-kurultai.tunduk.gov.kg/api/",
  dev: "https://admkurultai.tunduk.kg/api/",
  prd: "https://admkurultai.tunduk.kg/api/",
};


export const api = axios.create({ baseURL: baseUrl.dev });

api.interceptors.request.use(
  (request) => {
    request.headers.set("Content-Type", "application/json");
    return request;
  },
  (error) => {
    store.dispatch(
      appActions.setErrorMessage(
        "Произошла ошибка на клиенте, попробуйте через некоторое время!"
      )
    );
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    store.dispatch(
      appActions.setErrorMessage("Произоша ошибка на сервере, попрбуйте позже!")
    );

    return Promise.reject(error);
  }
);
