import Slide1 from 'assets/images/mock/slide1.jpg'
import Slide2 from 'assets/images/mock/slide2.jpg'
import Slide3 from 'assets/images/mock/slide3.jpg'
import Slide4 from 'assets/images/mock/slide4.jpg'

import Slide5 from 'assets/images/mock/slide5.jpg'
import Slide6 from 'assets/images/mock/slide6.jpg'
import Slide7 from 'assets/images/mock/slide7.jpg'
import Slide8 from 'assets/images/mock/slide8.jpg'
import Slide9 from 'assets/images/mock/slide9.jpg'

export const slider_mocks = [
	Slide1,
	Slide2,
	Slide3,
	Slide4,
	Slide5,
	Slide6,
	Slide7,
	Slide8,
	Slide9,
]
