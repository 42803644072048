import { baseUrl } from "api/api";
import { useState } from "react";
import { createSignalRContext } from "react-signalr/signalr";
import styles from "./QueuePageVersion2.module.scss";
import ImgMontain from "assets/images/header/montain.png";
import { motion, AnimatePresence } from "framer-motion";
import CurLogo from "assets/images/cur-logo.png";
import { Snows } from "components/Snow/Snows";

const dataCache = {
  delegates: null,
  photos: new Map(),
};


const SignalRContext = createSignalRContext();

const WEBSOCKET_URL = baseUrl.dev.replace("/api/", "") + "/queueHub";

export const QueuePageVersion2 = ({ navigates }) => {
  const [speakerNow, setSpeakerNow] = useState(null);
  const [othersSpeakers, setOtherSpeakers] = useState([]);

  SignalRContext.useSignalREffect("ReceiveUpdatedQueue", (queue) => {
    const data = JSON.parse(queue);
    if (!data) return;

    if (!dataCache.delegates || dataCache?.delegates?.length === "undefined") {
      dataCache.delegates = data;

      data.forEach((speaker) => {
        if (speaker?.Citizen?.PhotoPath) {
          dataCache.photos.set(speaker.CitizenId, speaker.Citizen.PhotoPath);
        }
      });
    }

    const delegates = data.filter((speaker) => !speaker.State);
    setSpeakerNow(delegates.shift());
    setOtherSpeakers(delegates);
  });

  const getPhoto = (speaker) => {
    if (dataCache.photos.has(speaker.CitizenId)) {
      return dataCache.photos.get(speaker.CitizenId);
    }

    return speaker.Citizen.PhotoPath;
  };

  return (
    <SignalRContext.Provider
      connectEnabled={true}
      accessTokenFactory={() => ""}
      dependencies={[""]}
      url={WEBSOCKET_URL}
    >
      {!speakerNow && !othersSpeakers.length ? (
        <div
          style={{
            fontSize: 70,
            display: "flex",
            alignItems: "center",
            height: "100%",
            width: "100%",
            position: "absolute",
            justifyContent: "center",
          }}
        >
          Бардык делегаттар сүйлөп бүтүштү!
        </div>
      ) : (
        <div className={styles.queue}>
          {speakerNow ? (
            <div className={styles.container}>
              <SpeakerNow
                speakerNow={speakerNow}
                othersSpeakersLength={othersSpeakers.length}
                navigates={navigates}
                getPhoto={getPhoto}
              />
            </div>
          ) : null}
          {othersSpeakers.length ? (
            <div className={styles.container}>
              <div className={styles.nav}>
                {navigates && navigates.prev}
                <p className={styles.text}>Кезекте</p>
                {navigates && navigates.next}
              </div>
              <div className={styles.list}>
                <AnimatePresence mode="popLayout">
                  {othersSpeakers.map((speaker) => (
                    <motion.div
                      initial={{ x: 100 }}
                      animate={{ x: 0 }}
                      transition={{ type: "spring", duration: 2 }}
                      key={speaker.OrderNumber}
                      id={speaker.Id}
                      exit={{ scale: 0, y: -400 }}
                    >
                      <div className={styles.speaker}>
                        <p className={styles.smallNumber}>
                          {speaker.OrderNumber}
                        </p>
                        <div className={styles.smallAvatar}>
                          <img
                            src={
                              dataCache?.photos?.has(speaker.CitizenId)
                                ? dataCache.photos.get(speaker.CitizenId)
                                : speaker.Citizen.PhotoPath
                            }
                            alt="avatar"
                          />
                        </div>
                        <div>
                          <p className={styles.smallName}>
                            {speaker.Citizen.LastName}{" "}
                            {speaker.Citizen.FirstName}{" "}
                            {speaker.Citizen.PaternalName}
                          </p>
                          <p className={styles.smallLocation}>
                            {speaker.Citizen.Location.Value}
                          </p>
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </SignalRContext.Provider>
  );
};

export const SpeakerNow = ({
  speakerNow,
  othersSpeakersLength,
  navigates,
  getPhoto,
}) => (
  <div>
    <p className={styles.text}>Учурда</p>
    <motion.div
      className={styles.speakerNow}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", duration: 2 }}
      key={speakerNow?.OrderNumber}
    >
      {/* <div className={styles.snows}>
        <Snows />
      </div> */}
      <img className={styles.curlogo} src={CurLogo} alt="logo" />
      <p className={styles.number}>{speakerNow.OrderNumber}</p>
      <div className={styles.avatar}>
        <img src={getPhoto(speakerNow)} alt="avatar" />
      </div>
      <div>
        <p className={styles.name}>
          {speakerNow.Citizen.LastName} {speakerNow.Citizen.FirstName}{" "}
          {speakerNow.Citizen.PaternalName}
        </p>
        <p className={styles.location}>{speakerNow.Citizen.Location.Value}</p>
        <img src={ImgMontain} alt="montain" className={styles.montain} />
      </div>
    </motion.div>
    {!othersSpeakersLength && navigates ? (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {navigates.prev} {navigates.next}
      </div>
    ) : null}
  </div>
);
