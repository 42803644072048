export const resources = {
	ru: {
		translation: {
			etnos: 'Этнический представитель',
			migrant: 'Трудовые мигранты за рубежом',
			count: 'Количество',
			monitoring: {
				byGender: 'По полу',
				byAges: 'По возрасту',
				byCategories: 'По категориям',
				byRegions: 'По регионам',
				willbe:
					'Мониторинг исполнения появится после добавления предложений делегатов',
			},
			home: {
				statsCard: {
					age: 'Возраст',
					gender: 'Пол',
					more: 'Подробнее',
				},
				title: {
					order: 'Нормативные правовые акты Народного Курултая',
					delegate: 'Делегаты  избранные в 2024 году',
					video: 'Курултай 2023-2024',
					curultai: 'Народный Курултай 2024',
				},
				order: {
					doc1: '"О проведении Народного Курултая" Указ Президента Кыргызской Республики',
					doc2: 'Конституционный Закон Кыргызской Республики «О Народном Курултае»',
					doc3: 'ВРЕМЕННОЕ ПОЛОЖЕНИЕ "Об организационном комитете по проведению Народного Курултая"',
					doc4: 'СПРАВКИ-ОБЪЕКТИВКИ ГРАЖДАН, ВЫДВИНУТЫХ КАНДИДАТАМИ НА ИЗБРАНИЕ ПРЕДСТАВИТЕЛЕМ НАРОДНОГО КУРУЛТАЯ В СОВЕТ ПО ДЕЛАМ ПРАВОСУДИЯ КЫРГЫЗСКОЙ РЕСПУБЛИКИ',
				},
				curultai: {
					date: '20 ДЕКАБРЯ ЭТОГО ГОДА',
					text: 'В городе Бишкек пройдёт очередной III Народный Курултай. Соответствующий указ подписал Президент Садыр Жапаров.',
					desc: '"В целях дальнейшего укрепления основ народной власти, национального сознания, единства и согласия народа Кыргызской Республики, консолидации гражданского общества, устойчивого развития страны, в соответствии со статьями 3, 11 конституционного Закона Кыргызской Республики «О Народном Курултае», статьями 70, 71 Конституции Кыргызской Республики"',
				},
				header: {
					button: 'Делегаты',
					stats: 'Статистика делегатов',
					monitor: 'Мониторинг исполнения',

					menu: {
						1: 'Главная',
						2: 'Правовые акты',
						3: 'Дата проведения',
						4: 'Делегаты',
						5: 'Видео',
					},
				},
			},
			delegats: {
				willYearText: 'году появится cписок делегатов',

				title: 'Делегаты',
				oneTitle: 'Делегат',
				search: 'Поиск',
				filters: {
					region: 'Область',
					district: 'Район',
					ak: 'Сельская местность',
					allOblast: 'Все',
				},
				year: {
					text: 'Выберите год',
				},
				notfound: 'По данному фильтру ничего не найдено!',
				select: {
					firstSelect: 'Выберите сначала: ',
				},
			},
			delegat: {
				info: {
					year: 'Год рождения',
					whereFrom: 'Гражданство',
					nation: 'Национальность',
					education: 'Образование, специальность, ученая степень',
					workPlace: 'Место работы',
				},
				question: {
					text: 'Предложение',
					answer: 'Ответ',
					noAnswer: 'Появится ответ',
				},
				pinned: 'Закрепленные файлы',
			},
			footer: {
				1: {
					title: 'Разделы сайта',
					data: ['Делегаты', 'Статистика делегатов', 'Мониторинг исполнения'],
				},
				2: {
					title: 'Нормативные правовые акты',
					data: [
						'О проведении Народного Курултая Указ Президента Кыргызской Республики',
						'Конституционный Закон Кыргызской Республики «О Народном Курултае»',
						'ВРЕМЕННОЕ ПОЛОЖЕНИЕ Об организационном комитете по проведению Народного Курултая',
					],
				},
				3: {
					title: 'Информационные ресурсы',
					data: [
						'Официальный сайт Президента КР',
						'Официальный сайт Правительства КР',
						'Министерство Цифрового Развития КР',
					],
				},
				contact: {
					title: 'Контакты',
					text1:
						'Администрация Президента Кыргызской Республики, Проспект Чингиза Айтматова, 301Чон-Арык с., Ленинский район, Бишкек, 720016',
					text2: 'Телефон доверия Администрации Президента +996 (312) 55‒77‒22',
					text3: '',
				},
			},
			stats: {
				kyrgyzstan: 'Общие делегаты',
				questionTitle: 'Предложения',
				categoryTitle: 'Категории предложений',
				delegatTitle: 'Делегатов',
				genderTitle: 'Пол',
				ageTitle: 'Возраст',
				woomanTitle: 'Женщины',
				manTitle: 'Мужчины',
				ageCountTitle: 'Делегатов',
				education: 'Образование',
				speciality: 'Специальность',
				academic: 'Ученая степень',
			},
		},
	},
	kg: {
		translation: {
			etnos: 'Этностук өкүлчүлүк',
			migrant: 'Чет өлкөдөгү эмгек мигранттары',
			count: 'Саны',
			monitoring: {
				byGender: 'Жынысы боюнча',
				byAges: 'Жаш курак боюнча',
				byCategories: 'Категория боюнча',
				byRegions: 'Региондор боюнча',
				willbe:
					'Аткаруу мониторинги делегаттар сунуш бергенден кийин пайда болот',
			},
			home: {
				statsCard: {
					age: 'Жаш курагы',
					gender: 'Жынысы',
					more: 'Кененирээк',
				},
				title: {
					order: 'Элдик Курултайдын ченемдик укуктук актылары',
					delegate: '2024-жылы шайланган делегаттар',
					video: 'Курултай 2023-2024',
					curultai: 'Элдик Курултай 2024',
				},
				order: {
					doc1: '"Элдик Курултайды өткөрүү жөнүндө" Кыргыз Республикасынын Президентинин Жарлыгы',
					doc2: '“Элдик Курултай жөнүндө” Кыргыз Республикасынын конституциялык Мыйзамы',
					doc3: '"Элдик Курултайды өткөрүү жана делегаттарды шайлоо боюнча" УБАКТЫЛУУ ЖОБО',
					doc4: 'КЫРГЫЗ РЕСПУБЛИКАСЫНЫН СОТ АДИЛЕТТИГИ ИШТЕРИ БОЮНЧА КЕҢЕШИНЕ ЭЛДИК КУРУЛТАЙДЫН ӨКҮЛҮ БОЛУП ШАЙЛАНУУГА ТАЛАПКЕР КАТАРЫ КӨРСӨТҮЛГӨН ЖАРАНДАРДЫН МААЛЫМДАМА-ОБЪЕКТИВКАЛАРЫ',
				},
				curultai: {
					date: 'УШУЛ ЖЫЛДЫН 20 - ДЕКАБРЫНДА',
					text: 'Бишкек шаарында кезектеги III Элдик Курултай өтөт. Тиешелүү Жарлыкка Президент Садыр Жапаров кол койду.',
					desc: `Элдик бийликтин негиздерин мындан ары чыңдоо, өлкөдө ынтымакты бекемдөө, жарандык коомду консолидациялоо, улуттук аң-сезимди өнүктүрүү, аймактык көйгөйлөрдү чечүү, ошондой эле өлкөнү туруктуу өнүктүрүү максатында, “Элдик Курултай жөнүндө” КР конституциялык Мыйзамынын 3,11-беренелерине, "Кыргыз Республикасынын Конституциясынын 70, 71-беренелерине ылайык`,
				},
				header: {
					button: 'Делегаттар',
					stats: 'Делегаттардын статистикасы',
					monitor: 'Аткаруу мониторинги',
					menu: {
						1: 'Башкы',
						2: 'Укуктук актылар',
						3: 'Өткөрүү датасы',
						4: 'Делегаттар',
						5: 'Видео',
					},
				},
			},
			delegats: {
				willYearText: 'жылы делегаттардын тизмеси пайда болот',
				title: 'Делегаттар',
				oneTitle: 'Делегат',
				search: 'Издөө',
				filters: {
					region: 'Облусу',
					district: 'Району',
					ak: 'Айыл Аймагы',
					allOblast: 'Баардыгы',
				},
				year: {
					text: 'Жылды тандоо',
				},
				notfound: 'Бул чыпка үчүн эч нерсе табылган жок!',
				select: {
					firstSelect: 'Биринчи тандоо: ',
				},
			},
			delegat: {
				info: {
					year: 'Туулган жылы',
					whereFrom: 'Жарандыгы',
					nation: 'Улуту',
					education: 'Билими, адистиги, окумуштуу даражасы',
					workPlace: 'Иштеген жери',
				},
				question: {
					text: 'Сунуш',
					answer: 'Жооп',
					noAnswer: 'Жооп болот',
				},
				pinned: 'Кошулган файлдар',
			},
			footer: {
				1: {
					title: 'Сайттын бөлүмдөрү',
					data: [
						'Делегаттар',
						'Делегаттардын статистикасы',
						'Аткаруу мониторинги',
					],
				},
				2: {
					title: 'Ченемдик укуктук актылары',
					data: [
						'“Элдик Курултай жөнүндө” Кыргыз Республикасынын конституциялык Мыйзамы',
						'"Элдик Курултайды өткөрүү жөнүндө" Кыргыз Республикасынын Президентинин Жарлыгы',
						'"Элдик Курултайды өткөрүү жана делегаттарды шайлоо боюнча" убактылуу жобо',
						'КЫРГЫЗ РЕСПУБЛИКАСЫНЫН СОТ АДИЛЕТТИГИ ИШТЕРИ БОЮНЧА КЕҢЕШИНЕ ЭЛДИК КУРУЛТАЙДЫН ӨКҮЛҮ БОЛУП ШАЙЛАНУУГА ТАЛАПКЕР КАТАРЫ КӨРСӨТҮЛГӨН ЖАРАНДАРДЫН МААЛЫМДАМА-ОБЪЕКТИВКАЛАРЫ',
					],
				},
				3: {
					title: 'Маалыматтык ресурстар',
					data: [
						'КР Президентинин расмий сайты',
						'КР Өкмөтүнүн расмий сайты',
						'КР Санариптик Өнүктүрүү Министрлиги',
					],
				},
				contact: {
					title: 'Байланышуу',
					text1:
						'Кыргыз Республикасынын Президентинин Администрациясы, Чыңгыз Айтматов Проспекти, 301 Чоң-Арык айылы, Ленин району, Бишкек, 720016',
					text2:
						'Президенттик Администрациясынын ишеним телефону +996 (312) 55‒77‒22',
					text3: '',
				},
			},
			stats: {
				kyrgyzstan: 'Жалпы делегаттар',
				questionTitle: 'Сунуштар',
				categoryTitle: 'Сунуштардын категориялары',
				delegatTitle: 'Делегат',
				genderTitle: 'Жынысы',
				ageTitle: 'Жаш курак',
				woomanTitle: 'Аялдар',
				manTitle: 'Эркектер',
				ageCountTitle: 'Делегат',
				education: 'Билими',
				speciality: 'Адистиги',
				academic: 'Илимий даражасы',
			},
		},
	},
}
