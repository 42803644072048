import { UseData } from "hooks/UseData";
import { Menu } from "features/Statistic/Stat/MainCard/Menu/Menu";
import React, { useEffect, useState } from "react";

import styles from "./StatSection.module.scss";
import { GenderStat } from "features/Statistic/Stat/Rechart/PieChart";
import { useDispatch, useSelector } from "react-redux";
import { statisticActions } from "store/slices/Statistic.slice";
import { AgesStat } from "features/Statistic/Stat/Rechart/BarChart";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Stats = [
  {
    id: 0,
    card: GenderStat,
  },
  {
    id: 1,
    card: AgesStat,
  },
];

export const StatSection = () => {
  const { regions, selectedRegion } = useSelector((state) => state.stats);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [active, setAcitve] = useState(Stats[0].id);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(statisticActions.setSelectedRegion(regions[0]));
  }, [regions]);

  if (!selectedRegion) return;

  return (
    <div>
      <div className={styles.card}>
        <div className={styles.content}>
          <div>
            <Menu />
          </div>
          <div className={styles.stats}>
            <div className={styles.nav}>
              <button
                type="button"
                className={classNames(
                  styles.switcher,
                  active === 0 && styles.active
                )}
                onClick={() => setAcitve(0)}
              >
                {t("home.statsCard.gender")}
              </button>
              <button
                type="button"
                className={classNames(
                  styles.switcher,
                  styles.switcher,
                  active === 1 && styles.active
                )}
                onClick={() => setAcitve(1)}
              >
                {t("home.statsCard.age")}
              </button>
            </div>
            <div className={styles.statCard}>
              {Stats.map(
                (item) => item.id === active && <item.card key={item.id} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.to}>
          <button type="button" onClick={() => navigate("/stats")}>
            {t("home.statsCard.more")}
          </button>
        </div>
      </div>
    </div>
  );
};
