import React, { useMemo } from "react";
//
import styles from "./RegionCard.module.scss";
import { motion } from "framer-motion";

import { MainCard } from "./MainCard/MainCard";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  CategoryQuestionStat,
  GenderStat,
  QuestionStat,
  AcademicDegreeStat,
} from "./Rechart/PieChart";
import { AgesStat, EducationStat, ProfessionStat } from "./Rechart/BarChart";

export const Stat = () => {
  const { selectedRegion } = useSelector((state) => state.stats);
  const { t, i18n } = useTranslation();
  const cards = useMemo(
    () => [
      {
        card: MainCard,
        title: selectedRegion?.regionName,
        regionName: "",
      },
      {
        card: GenderStat,
        title: t("stats.genderTitle"),
        regionName: selectedRegion?.regionName,
      },
      {
        card: AgesStat,
        title: t("stats.ageTitle"),
        regionName: selectedRegion?.regionName,
      },

      // {
      //   card: QuestionStat,
      //   title: t("stats.questionTitle"),
      //   regionName: selectedRegion?.regionName,
      // },
      {
        card: EducationStat,
        title: t("stats.academic"),
        regionName: selectedRegion?.regionName,
      },
      {
        card: ProfessionStat,
        title: t("stats.speciality"),
        regionName: selectedRegion?.regionName,
      },
      // {
      //   card: AcademicDegreeStat,
      //   title: t("stats.categoryTitle"),
      //   regionName: selectedRegion?.regionName,
      // },
      {
        card: CategoryQuestionStat,
        title: t("stats.education"),
        regionName: selectedRegion?.regionName,
      },
    ],
    [selectedRegion, i18n.language]
  );

  if (!selectedRegion) return;

  return (
    <div>
      <div className={styles.region}>
        {cards.map((item, key) => (
          <div className={styles.card} key={key}>
            <div className={styles.title}>
              <motion.p
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                key={selectedRegion.regionName}
              >
                {item.title}{" "}
                {item.regionName && (
                  <span>
                    (
                    {selectedRegion.regionId === 4948
                      ? t("stats.kyrgyzstan")
                      : item.regionName}
                    )
                  </span>
                )}
              </motion.p>
            </div>

            <item.card />
          </div>
        ))}
      </div>
    </div>
  );
};
