import { useEffect, useState } from "react";
import styles from "./Queue.module.scss";
import { QueuePage } from "./QueuePage";

import {
  PatchNextSlide,
  PatchPrevSlide,
  PostLoginAdmin,
} from "api/routes/Login";
import { QueuePageVersion2 } from "./Version2/QueuePageVersion2";

export const QueueAdmin = () => {
  const [token, setToken] = useState("");

  useEffect(() => {
    const storage = localStorage.getItem("accessToken");

    if (storage) {
      setToken(JSON.parse(storage).accessToken);
    }
  }, []);

  //  useEffect(() => {
  //    const handleKeyDown = (e) => {
  //      if (e.key === "ArrowLeft") {
  //        prevHandler();
  //      } else if (e.key === "ArrowRight") {
  //        nextHandler();
  //      }
  //    };
  //    window.addEventListener("keydown", handleKeyDown);

  //    return () => {
  //      window.removeEventListener("keydown", handleKeyDown);
  //    };
  //  }, [token]);
  

  if (!token) return <LoginAdmin setToken={setToken} />;

  const nextHandler = async () => {
    try {
      await PatchNextSlide({ token });
    } catch (e) {
      localStorage.removeItem("accessToken");
      setToken("");
    }
  };

  const prevHandler = async () => {
    try {
      await PatchPrevSlide({ token });
    } catch (e) {
      localStorage.removeItem("accessToken");
      setToken("");
    }
  };

  const navigates = {
    prev: (
      <button className={styles.nav} type="button" onClick={prevHandler}>
        {"<"}
      </button>
    ),
    next: (
      <button className={styles.nav} type="button" onClick={nextHandler}>
        {">"}
      </button>
    ),
  };

  return (
    <div>
      <QueuePageVersion2 navigates={navigates} />
    </div>
  );
};

export const LoginAdmin = ({ setToken }) => {
  const [form, setForm] = useState({
    name: "",
    password: "",
  });

  const fromHandler = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };

  const loginHandler = async () => {
    try {
      const response = await PostLoginAdmin({ ...form });

      localStorage.setItem(
        "accessToken",
        JSON.stringify({ accessToken: response.data.token })
      );

      setToken(response.data.token);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className={styles.modal}>
      <h2 style={{ textAlign: "center" }}>Курултай</h2>
      <input
        type="text"
        placeholder="Имя"
        value={form.name}
        id="name"
        onChange={fromHandler}
      />
      <input
        onKeyDown={(e) => {
          if (e.code === "Enter") loginHandler();
        }}
        type="password"
        placeholder="Пароль"
        value={form.password}
        id="password"
        onChange={fromHandler}
      />
      <div className={styles.btn}>
        <button type="button" onClick={loginHandler}>
          Войти
        </button>
      </div>
    </div>
  );
};
